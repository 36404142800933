import React from "react";
import {graphql} from "gatsby";
import {Helmet} from "gatsby-plugin-react-i18next";
import Typography from "@mui/material/Typography";
import Box, {BoxProps} from "@mui/material/Box";
import SiteLayout from "components/layouts/SiteLayout";
import Section from "components/molecules/Section";
import SectionTitle from "components/atoms/SectionTitle";

const SectionContent = (props: BoxProps) => (
  <Box
    {...props}
    sx={{
      "& h2": {
        px: {sx: 2, md: 4, lg: 6},
        pt: {sx: 2, md: 4, lg: 6}
      },
      "& p": {
        px: {sx: 2, md: 4, lg: 6}
      },
      ...props.sx
    }}
  />
);

export default function RemoveAccountPage() {
  return (
    <SiteLayout>
      <Helmet>
        <title>Usunięcie konta</title>
      </Helmet>
      <Section>
        <SectionTitle>
          Jak usunąć konto w aplikacji DokDok?
        </SectionTitle>
        <SectionContent>
          <Typography variant="h2">
            Aby usunąć konto w apliacji DokDok należy wykonać następujace kroki:
          </Typography>
          <Typography>
            <ol>
              <li>
                W ustawieniach w <strong>aplikacji mobilnej DokDok</strong> należy wybrać opcję <strong>“usuń konto”</strong> 
              </li>
              <li>
                Po wybraniu tej opcji, na ekranie pojawi się okno z wiadomością mailową z przygotowaną treścią o chęci usunięcia konta w aplikacji DokDok.
                Po wysłaniu, wiadomość zostaje przekazana do naszej obsługi pacjenta, która następnie, zgodnie z żądaniem, <strong>usuwa konto użytkownika w aplikacji mobilnej DokDok.</strong>
              </li>
              <li>
                Po usunięciu konta w aplikacji, obsługa pacjenta przesyła potwierdzenie na adres mail użytkownika.
              </li>
            </ol>
          </Typography>
          <br />
        </SectionContent>
      </Section>
    </SiteLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
